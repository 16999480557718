import React, { Component } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, Container } from "reactstrap";
// import DoughnutChart from "components/Charts/DoughnutChart";
import firebase from "firebase/app";
import moment from "moment";
import "firebase/firestore";
import _ from "lodash";

class AgentInformationModal extends Component {
  state = {
    agentInfo: false,
    buyerModal: false,
    loadingOfferData: false,
    logo: null,
    image: "/images/face-placeholder.gif",
    agent: null,
    // this.props.globalAgents: this.props.user?.hasOwnProperty("myGlobalAgents")
    //   ? this.props.user.myGlobalAgents
    //   : [],
    added: false,
    closedDealsData: null,
  };

  componentDidMount() {
    this.setState({
      added: this.props.globalAgents?.some(
        (agent) => agent.agentName === this.props.offer.agentName
      ),
    });
    this.fetchOfferActivityData();
    this.fetchClosedDealsData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.agentInfo && this.state.agentInfo) {
      this.fetchOfferActivityData();
      this.fetchClosedDealsData();
    }

    if (prevProps.offer !== this.props.offer) {
      this.fetchOfferActivityData();
      this.fetchClosedDealsData();
    }

    if (prevProps.globalAgents !== this.props.globalAgents) {
      this.setState({
        added: this.props.globalAgents?.some(
          (agent) => agent.agentName === this.props.offer.agentName
        ),
      });
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.globalAgents !== this.props.globalAgents) {
  //     this.setState({
  //       added: this.props.globalAgents?.some(
  //         (agent) => agent.agentName === this.props.offer.agentName
  //       ),
  //     });
  //   }
  // }
  fetchClosedDealsData = async () => {
    const { offer } = this.props;
    if (!offer?.licenseNo) return;

    this.setState({ loadingClosedDeals: true });

    try {
        const fetchClosedDeals = firebase.functions().httpsCallable("getSellerClosedListings");
        
        const response = await fetchClosedDeals({ licenseNo: offer.licenseNo });
        this.setState({ closedDealsData: response.data.listings, loadingClosedDeals: false });

    } catch (error) {
        console.error("Error fetching closed deals data:", error);
        this.setState({ closedDealsData: null, loadingClosedDeals: false });
    }
};

  

  fetchOfferActivityData = async () => {
    const { offer } = this.props;
    const db = firebase.firestore();
  
    try {
      this.setState({ loadingOfferData: true });

      const offersSnapshot = offer?.agentName
      ? await db.collection("offers").where("agentName", "==", offer.agentName).get()
      : { docs: [] };

      const licenseOffersSnapshot = offer?.licenseNo
      ? await db.collection("offers").where("licenseNo", "==", offer.licenseNo).get()
      : { docs: [] };

      const allOffers = [...offersSnapshot.docs, ...licenseOffersSnapshot.docs]
      .map((doc) => ({ ...doc.data(), id: doc.id }))
      .filter(
        (v, i, a) =>
          a.findIndex((t) => t.id === v.id) === i && v.status !== "Deleted"
      );
      // Calculate Metrics
      const totalOffers = allOffers.length;
      const acceptedOffers = allOffers.filter(
        (o) => o.status === "Accepted" || o.status === "Executed"
      );
      const nonAcceptedOffers = allOffers?.filter(o => o.status === "Not Accepted")
      const terminatedOffers = allOffers.filter((o) => o.status === "Terminated");
      const withdrawnOffers = allOffers.filter((o) => o.status === "Withdrawn");
      // Offer Price-to-Sold Price Ratio
      const avgPriceRatio =
        allOffers.length > 0
          ? (
              allOffers.reduce((sum, o) => {
                const offerPrice = parseFloat(o.purchasePrice || 0);
                const actualPrice = parseFloat(o.actual_price || 0);
                return sum + ((offerPrice - actualPrice) / actualPrice) * 100;
              }, 0) / allOffers.length
            ).toFixed(2)
          : 0;
  
      // Offer Acceptance Rate
      const acceptanceRate =
        totalOffers > 0
          ? ((acceptedOffers.length / totalOffers) * 100).toFixed(2)
          : 0;

      // Offer Non-Acceptance Rate
      const nonAcceptanceRate =
      totalOffers > 0
        ? ((nonAcceptedOffers.length / totalOffers) * 100).toFixed(2)
        : 0;
  
      // Offer Stability Indicators
      const terminationRate =
        totalOffers > 0
          ? ((terminatedOffers.length / totalOffers) * 100).toFixed(2)
          : 0;
  
      const withdrawalRate =
        totalOffers > 0
          ? ((withdrawnOffers.length / totalOffers) * 100).toFixed(2)
          : 0;
  
      // Last Offer Sent
      const lastOffer = allOffers.sort(
        (a, b) => b.createdAt - a.createdAt
      )[0] || null;

      const lastOfferString = lastOffer
      ? `${moment(lastOffer.createdAt * 1000).format(
          "MMM D, YYYY"
        )} – ${lastOffer.address || "Location Unknown"} – ${
          lastOffer.status || "Status Unknown"
        }`
      : "No offers available";
  
      // Update State
      this.setState({
        offerActivityData: {
          totalOffersSubmitted: totalOffers,
          avgPriceRatio,
          acceptanceRate,
          nonAcceptanceRate,
          terminationRate,
          withdrawalRate,
          lastOffer: lastOfferString
        },
        loadingOfferData: false
      });
      
    } catch (error) {
      console.error("Error fetching offer activity data:", error);
      this.setState({ loadingOfferData: false });
    }
  };

  setImageById = (id) => {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const realtorImageRef = storageRef.child(`images/users/${id}.jpg`);
    const builderLogoRef = storageRef.child(`images/logos/${id}.jpg`);
    builderLogoRef
      .getDownloadURL()
      .then((url) => {
        this.setState({ logo: url });
      })
      .catch((error) => {});
    realtorImageRef
      .getDownloadURL()
      .then((url) => {
        this.setState({ image: url });
      })
      .catch((error) => {});
  };

  constructor(props) {
    super(props);
    const { offer, sellerAgent} = this.props;
    const db = firebase.firestore();
    if (sellerAgent) {
      db.collection("realtors")
        .where("realtor_name", "==", offer.viewedBy)
        .get()
        .then((snap) => {
          if (snap.docs.length > 0) {
            const doc = snap.docs[0];
            this.setImageById(doc.id);
            const data = doc.data();
            data.id = doc.id;
            this.setState({ agent: data });
          } else {
            console.error(
              "No Agent information found. using given number. this should not happen."
            );
          }
        });
    } else {
      if (offer.licenseNo) {
        db.collection("realtors")
          .where("realtor_license", "==", offer.licenseNo)
          .get()
          .then((snap) => {
            if (snap.docs.length > 0) {
              const doc = snap.docs[0];
              this.setImageById(doc.id);
              const data = doc.data();
              data.id = doc.id;
              this.setState({ agent: data });
            } else {
              console.error(
                "No Agent information found. using given number. this should not happen."
              );
            }
          });
      }
    }
  }

  render() {
    const { agentInfo, agent, image, added, buyerModal, offerActivityData, closedDealsData } = this.state;
    const { offer, comingFrom, sellerAgent, buyerInfo, showBuyerAgentStats } = this.props;
    return (
      <>
        <td>
          <p
            onClick={(e) => {
              this.setState(buyerInfo ? { buyerModal: true } : { agentInfo: true });
              e.preventDefault();
            }}
            className={
              comingFrom
                ? `underline black-cursor-pointer ${comingFrom==="globalLocationChart" ? "mb-0" : ""}`
                : "analytics-lined-td"
            }
          >
            {buyerInfo ? offer?.buyerName : sellerAgent ?
              offer?.viewedBy : offer?.agentName}
          </p>
        </td>
        {comingFrom === "globalLocationChart" &&
          `, ${agent?.brokerage_name || offer?.brokerageName}`}
        <Modal
          isOpen={agentInfo}
          size="md"
          fade={false}
          toggle={() => this.setState({ agentInfo: !agentInfo })}
        >
          <ModalHeader
            className="modal-header"
            toggle={() => this.setState({ agentInfo: !agentInfo })}
          ></ModalHeader>
          <ModalBody className="modal-body">
            <Container>
              <Row>
                <Col>
                  <div className="flexCenter">
                    <img
                      alt="Agent"
                      src={image}
                      className="analytics-agent-img"
                    />
                    <h3>{agent?.realtor_name || offer.agentName}</h3>
                  </div>
                  {showBuyerAgentStats && <div className="offer-activity-data">
                    {this.state.loadingOfferData ? (
                      <p>Loading offer activity data...</p>
                    ) : offerActivityData ? (
                      <>
                        <div className="metric-card">
                          
                          {closedDealsData && closedDealsData.totalClosedDeals > 0 && (
                            <div style={{
                              backgroundColor: "#0080000a",
                              borderWidth: 0
                            }} className="metric-card mb-2">
                              <h6 className="text-primary">
                              Buyer's Agent MLS Verified Closings 
                              {/* <span className="badge badge-success ml-2">✔</span> */}
                              </h6>

                              <div className="row-flex-start">
                                <h6>Total Closed Deals:</h6>
                                <p className="ml-2">{closedDealsData.totalClosedDeals}</p>
                              </div>

                              <div className="row-flex-start">
                                <h6>Closed Deals (Last 6 Months):</h6>
                                <p className="ml-2">{closedDealsData.closedDealsLast6Months}</p>
                              </div>

                              {Object.keys(closedDealsData.closedDealsByYear).length > 0 && (
                                <div className="mt-2">
                                  <h6>Closed Deals By Year:</h6>
                                  <ul className="ml-3">
                                    {Object.entries(closedDealsData.closedDealsByYear).map(([year, count]) => (
                                      <li key={year}>
                                        <strong>{year}:</strong> {count}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          )}
                          
                          <div className="row-flex-start">
                            <h6 style={{ minWidth: 70 }}>Total Offers Submitted: &nbsp;</h6>
                            <p>{offerActivityData?.totalOffersSubmitted || 0}</p>
                          </div>

                          <div className="row-flex-start">
                            <h6>Offer Price to List Price Ratio: &nbsp;</h6>
                            <p className={`metric-value ${offerActivityData?.avgPriceRatio > 0 ? 'text-success' : 'text-danger'}`}>
                              {offerActivityData?.avgPriceRatio > 0
                                ? `+${offerActivityData?.avgPriceRatio}% Above Market`
                                : `${offerActivityData?.avgPriceRatio}% Below Market`}
                            </p>
                          </div>

                          <div className="row-flex-start">
                            <h6 style={{minWidth: 70}}>Last Offer: &nbsp;</h6>
                            <p>{offerActivityData?.lastOffer}</p>
                          </div>
                          {[
                            {
                              label: "Acceptance Rate",
                              value: Number(offerActivityData?.acceptanceRate) || 0,
                              bgColor: "#28a745",
                            },
                            {
                              label: "Non-Acceptance Rate",
                              value: Number(offerActivityData?.nonAcceptanceRate) || 0,
                              bgColor: "#d91c43",
                            },
                            {
                              label: "Termination Rate",
                              value: Number(offerActivityData?.terminationRate) || 0,
                              bgColor: "#dc3545",
                            },
                            {
                              label: "Withdrawal Rate",
                              value: Number(offerActivityData?.withdrawalRate) || 0,
                              bgColor: "#ffc107",
                            },
                          ].map((metric, index) => (
                            <div key={index} className="mt-2">
                              <h6>{metric.label}</h6>
                              <div className="agent-info-progress">
                                <div
                                  className="agent-progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${metric.value}%`,
                                    backgroundColor: `${metric.value > 0 ? metric.bgColor : "#6c757d"}`,
                                  }}
                                  aria-valuenow={metric.value}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                                <span
                                  className="position-absolute w-100 text-center agent-info-custom-progress-text"
                                  style={{
                                    color: metric.value < 50 ? "#6c757d" : "#fff",
                                  }}
                                >
                                  {metric.value?.toFixed(2)}%
                                </span>
                              </div>
                            </div>
                          ))}
                          
                        </div>
                      </>
                    ) : (
                      <p>No data available</p>
                    )}
                </div>}
                  <div className="analytics-agent-details">
                    <div className="center-me mt-10 flex-row">
                      <i className="fa fa-phone phone-icon"></i>
                      <p>{agent?.realtor_phone || offer?.phoneNumber}</p>
                    </div>

                    <div className="center-me flex-row">
                      <i className="fa fa-envelope"></i>
                      <p>{agent?.realtor_email || offer?.email}</p>
                    </div>

                    {agent && (
                      <div className="center-me flex-row">
                        <p style={{ fontSize: 14, marginTop: "3px" }}>
                          <i className="flaticon-pin" /> {agent?.street_number}{" "}
                          {agent?.street_name}, {agent?.city} {agent?.state}{" "}
                          {agent?.zip_code}
                        </p>
                      </div>
                    )}
                    <div className="center-me flex-row">
                      <small style={{ fontWeight: "bold" }}>Brokerage &nbsp;</small>
                      <h6 style={{ marginBottom: 0, fontSize: 14  }}>
                        {agent?.brokerage_name || offer?.brokerageName}
                      </h6>
                    </div>
                    <div className="center-me flex-row">
                      <small style={{ fontWeight: "bold" }}>License # &nbsp;</small>
                      <p style={{ fontSize: 14 }}>
                        {agent?.realtor_license || offer?.licenseNo}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4 mb-5">
                    <div className="col-12 col-md-6 p-0 m-0 px-1 mx-auto">
                      <button
                        className="cancel-agent-btn mr-0 w-100 mb-2 mb-md-0"
                        onClick={() => this.setState({ agentInfo: false })}
                      >
                        Close
                      </button>
                    </div>

                    {comingFrom === "globalOffers" &&
                      offer?.sellerEmail != this.props.user.email && (
                        <div className="col-12 col-md-6 p-0 m-0 px-1">
                          <button
                            className="add-agent-btn mr-0 w-100"
                            onClick={() => {
                              const db = firebase.firestore();
                              if (!added) {
                                // this.state.this.props.globalAgents.push(this.props.offer);
                                db.collection("users")
                                  .doc(this.props.user.uid)
                                  .update({
                                    myGlobalAgents:
                                      firebase.firestore.FieldValue.arrayUnion(
                                        this.props.offer
                                      ),
                                  })
                                  .then(() => {
                                    console.log("succeed");
                                    this.props.getGlobalAgents();
                                  });
                                this.setState({ added: true });
                              } else {
                                // this.state.this.props.globalAgents.filter(
                                //   (offer) =>
                                //     offer.agentName !== this.props.offer.agentName
                                // );
                                db.collection("users")
                                  .doc(this.props.user.uid)
                                  .update({
                                    myGlobalAgents:
                                      firebase.firestore.FieldValue.arrayRemove(
                                        this.props.offer
                                      ),
                                  })
                                  .then(() => {
                                    console.log("succeed");
                                    this.props.getGlobalAgents();
                                  });
                                this.setState({ added: false });
                              }
                            }}
                          >
                            <i
                              class={`fa ${
                                !added ? "fa-user-plus" : "fa-user-times"
                              } mr-2`}
                              aria-hidden="true"
                            ></i>{" "}
                            {added ? "Remove Agent" : "Add Agent"}
                          </button>
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>


        <Modal
          dal
          isOpen={buyerModal}
          size="md"
          fade={false}
          toggle={() => this.setState({ buyerModal: !buyerModal })}
        >
          <ModalHeader
            className="modal-header"
            toggle={() => this.setState({ buyerModal: !buyerModal })}
          ></ModalHeader>
          <ModalBody className="modal-body">
            <Container>
              <Row>
                <Col>
                  <div className="flexCenter">
                    <img
                      alt="Agent"
                      src={image}
                      className="analytics-agent-img"
                    />
                    <h3>{offer?.buyerName}</h3>
                  </div>
                  <div className="analytics-agent-details">
                    {offer?.buyerPhoneNumber && <div className="analytics-row center-me mt-10 flex-row">
                      <i className="fa fa-phone phone-icon"></i>
                      <p>{offer?.buyerPhoneNumber}</p>
                    </div>}

                    {offer?.buyersEmail && <div className="analytics-row center-me flex-row">
                      <i className="fa fa-envelope"></i>
                      <p>{offer?.buyersEmail}</p>
                    </div>}

                  </div>
                  <div className="row mt-4 mb-5">
                    <div className="col-12 col-md-6 p-0 m-0 px-1 mx-auto">
                      <button
                        className="cancel-agent-btn mr-0 w-100 mb-2 mb-md-0"
                        onClick={() => this.setState({ buyerModal: false })}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default AgentInformationModal;
