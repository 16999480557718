import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { toast } from "react-toastify";
import FullReportPDF from "./FullReportPDF"; 
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { Doughnut } from "react-chartjs-2";
import titleInsuranceData from "./../utils/title_insurance_data.json";
import { PDFDownloadLink } from "@react-pdf/renderer";

const SellerNetSheetModal = (props) => {
  const { showModal, hideModal, offer, currentSeller } = props;
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const [activeTab, setActiveTab] = useState(1);
  const [isEditingNetAtClose, setIsEditingNetAtClose] = useState(false);
  const [saveClosingCostsForAll, setSaveClosingCostsForAll] = useState(false);
  const [showEditFieldsModal, setShowEsitFieldsModal] = useState(false);
  const [closingCostsDetails, setClosingCostsDetails] = useState();
  const [closingCostsEditConfirmation, setClosingCostsEditConfirmation] = useState(false);
  const [savedEstimates, setSavedEstimates] = useState([]);
  const [titleFees, setTitleFees] = useState([
    { name: "Escrow Fee", value: 525 },
    { name: "Attorney Fee (Deed)", value: 150 },
    { name: "Attorney Fee (Release of Lien)", value: 100 },
    { name: "Tax Certificate", value: 65 }
  ]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [otherFees, setOtherFees] = useState([]);
  const [customNetAtClose, setCustomNetAtClose] = useState("");
  const [calculatedData, setCalculatedData] = useState(null);
  const [showSnapshot, setShowSnapshot] = useState(false);
  const [saveEstimateModal, setSaveEstimateModal] = useState(false);
  const [showResults, setShowResults] = useState(offer?.net_a_seller_estimates?.length > 0);
  const [showClosingCosts, setShowClosingCosts] = useState(false);
  const [estimateName, setEstimateName] = useState("");
  const [savingEstimate, setSavingEstimate] = useState(false);
  const [taxType, setTaxType] = useState("percent");
  let twoDaysLater = new Date();
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [multipleOffers, setMultipleOffers] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  twoDaysLater.setDate(twoDaysLater.getDate() + 30);
  const formattedDate = twoDaysLater.toISOString().split("T")[0];
  const doughnutRef = useRef(null);

  const [formData, setFormData] = useState({
    calcType: "Conventional",
    price: "",
    sellerBF_value: "3",
    buyerBF_value: "3",
    BF_sellerbuyer_Split: "Seller", 
    SellerConcession_seller: "0",
    repairs: "",
    miscCosts: "",
    taxPercent: "2.59",
    taxAmountAnnual: "",
    loanBalance: "0",
    titlePolicyExpense: "Seller",
    interestRate: "4.25",
    closingDate: formattedDate,
    serviceContract: "0",
    miscellaneous: "0",
  });

  useEffect(() => {
    if(showModal) handleCalculateClick();
  }, [showModal]);
  
  useEffect(() => {
    if (offer) {
      fetchSellerData();
      fetchMultipleOffers();
      setFormData((prevData) => {
        const splitType = "Seller";
        return {
          ...prevData,
          calcType: offer.financingType || "Conventional",
          price: offer.purchasePrice || "",
          BF_sellerbuyer_Split: splitType,
          titlePolicyExpense: offer.titlePolicyExpense,
          closingDate: offer.closingDate || formattedDate,
          SellerConcession_seller: offer.sellerConcessions || "0",
          serviceContract: offer.residentialServiceContract || "0",
          buyerBF_value: splitType === "Buyer" ? "0" : splitType === "Split" ? "1.5" : "3",
          sellerBF_value: "3",
        };
      });
      if (offer?.net_a_seller_estimates?.length) {
        setSavedEstimates(offer.net_a_seller_estimates);
      }
    }
  }, [offer]);

  const fetchMultipleOffers = async () => {
    if (!offer?.seller || !offer?.id) return;
  
    try {
        const db = firebase.firestore();
        const offersRef = db.collection("offers");
        const snapshot = await offersRef.where("seller", "==", offer.seller).get();
  
        let titleFeesParam = [...titleFees];
        let otherFeesParam = [...otherFees];
  
        const sellerDoc = await db.collection("sellers").doc(offer.seller).get();
        if (sellerDoc.exists) {
            const sellerData = sellerDoc.data();
            if (sellerData?.net_a_seller) {
                if (sellerData.net_a_seller.titleFees && sellerData.net_a_seller.titleFees.length > 0) {
                    titleFeesParam = sellerData.net_a_seller.titleFees;
                }
                if (sellerData.net_a_seller.otherFees && sellerData.net_a_seller.otherFees.length > 0) {
                    otherFeesParam = sellerData.net_a_seller.otherFees;
                }
            }
        }
  
        if (!snapshot.empty) {
            const offers = snapshot.docs
                .map(doc => ({ ...doc.data(), id: doc.id }))
                .filter(item => item.status !== "Deleted" && item.id !== offer.id);
            const offersWithNet = await Promise.all(
                offers.map(async (offerItem) => {
                    const estimatedNet = calculateNetAtClose(
                        offerItem,
                        titleFeesParam,
                        otherFeesParam 
                    );
                    return { ...offerItem, estimated_net_at_close: estimatedNet };
                })
            );
  
            setMultipleOffers(offersWithNet);
        }
    } catch (error) {
        console.error("Error fetching multiple offers:", error);
    }
  };
  
  
  const fetchSellerData = async () => {
    try {
      if (offer?.seller) {
        const db = firebase.firestore();
        const sellerDoc = await db.collection("sellers").doc(offer.seller).get();

        if (sellerDoc.exists) {
          const sellerData = sellerDoc.data();
          if (sellerData?.net_a_seller) {
            setSaveClosingCostsForAll(true);
            if (sellerData.net_a_seller.titleFees) {
              setTitleFees(sellerData.net_a_seller.titleFees);
            }
            if (sellerData.net_a_seller.otherFees) {
              setOtherFees(sellerData.net_a_seller.otherFees);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const calculateNetAtClose = (offerItem, titleFeesParam, otherFeesParam) => {
    const salesPrice = parseFloat(offerItem.purchasePrice);
    const splitType = offerItem.titlePolicyExpense || "Seller";
    const sellerBF = (salesPrice * 3) / 100;
    const buyerBF = (salesPrice * (splitType === "Buyer" ? 0 : splitType === "Split" ? 1.5 : 3)) / 100;
    const sellerConcession = parseFloat( formData.SellerConcession_seller);
    const loanBalance = parseFloat( formData.loanBalance);
    const serviceContract = parseFloat(formData.serviceContract);
    const miscellaneous = parseFloat(formData.miscellaneous);
    const taxAmountAnnual = parseFloat(formData.taxAmountAnnual);
    const taxRate = parseFloat(formData.taxPercent);

    let specialLoanFee = 0;
    let specialLoanFeeLabel = "";
    if (offerItem.financingType === "VA") {
        specialLoanFee = 65;
        specialLoanFeeLabel = "Termite Fee (VA Loan)";
    } else if (offerItem.financingType === "Cash") {
        specialLoanFee = 55;
        specialLoanFeeLabel = "Maintenance Certificate (Cash Loan)";
    }

    // Calculate Title Insurance
    let titleInsurance = calculateTitleInsurance(salesPrice);
    formData.titlePolicyExpense === "Seller" ? titleInsurance = titleInsurance : formData.titlePolicyExpense === "Buyer" ? titleInsurance = 0 : titleInsurance = titleInsurance / 2;

    const totalTitleFees = (titleFeesParam).reduce((sum, fee) => sum + parseFloat(fee.value || 0), 0) + titleInsurance;

    // Calculate Other Fees
    const totalOtherFees = (otherFeesParam).reduce((sum, fee) => sum + parseFloat(fee.value || 0), 0);

    // Calculate Other Fees combined
    const otherFee = sellerBF + buyerBF + serviceContract + miscellaneous + specialLoanFee;

    // Calculate Closing Costs
    const closingCosts = totalTitleFees + totalOtherFees + otherFee;

    // Calculate Prorated Taxes
    const proratedTaxes = calculateProratedTaxes(salesPrice, taxRate, offerItem.closingDate, taxAmountAnnual);

    // Calculate Net At Close
    const netAtClose = salesPrice - (closingCosts + sellerConcession + loanBalance + proratedTaxes);

    return netAtClose;
};


  const toggleClosingCosts = () => {
    setShowClosingCosts(!showClosingCosts);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  
  //   const rawValue = value.replace(/,/g, "");
  
  //   setFormData({ ...formData, [name]: rawValue });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    const rawValue = value.replace(/,/g, "");
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: rawValue,
      ...(name === "BF_sellerbuyer_Split" && {
        buyerBF_value: value === "Buyer" ? "0" : value === "Split" ? "1.5" : "3",
      }),
    }));
  };

  const handleSaveEstimate = async () => {
    if (!estimateName.trim()) {
      toast.error("Please enter an Estimate Name.");
      return;
    }
  
    if (!offer || !offer.id) {
      toast.error("Offer ID is missing. Cannot save estimate.");
      return;
    }
  
    setSavingEstimate(true);
  
    try {
      const db = firebase.firestore();
      const estimateData = {
        estimateName,
        ...formData,
        customNetAtClose,
        createdAt: Math.floor(Date.now() / 1000),
      };

      if (!saveClosingCostsForAll) {
        estimateData.titleFees = titleFees;
        estimateData.otherFees = otherFees;
      }
      let updatedEstimates = [];

      if (selectedEstimate) {
        updatedEstimates = savedEstimates.map((estimate) =>
          estimate.createdAt === selectedEstimate.createdAt ? estimateData : estimate
        );
      } else {
        updatedEstimates = [...savedEstimates, estimateData];
      }

      if (selectedEstimate) {
        const updatedEstimates = savedEstimates.map((estimate) =>
          estimate.createdAt === selectedEstimate.createdAt ? estimateData : estimate
        );
        await db.collection("offers").doc(offer.id).update({
          net_a_seller_estimates: updatedEstimates
        });
      } else {
        await db.collection("offers").doc(offer.id).update({
          net_a_seller_estimates: firebase.firestore.FieldValue.arrayUnion(estimateData),
        });
      }

      if (saveClosingCostsForAll && offer.seller) {
        await db.collection("sellers").doc(offer.seller).update({
          net_a_seller: {
            titleFees,
            otherFees
          }
        });
        toast.success("Closing costs saved for all future estimates!");
      }
  
      toast.success("Estimate saved successfully!");
      await fetchUpdatedOfferData();
      toggleSaveEstimateModal();
      setEstimateName("");
    } catch (error) {
      console.error("Error saving estimate:", error);
      toast.error("Failed to save estimate. Please try again.");
    } finally {
      setSavingEstimate(false);
    }
  };

  const handleDeleteEstimate = async (estimateCreatedAt) => {
    if (!offer || !offer.id) {
      toast.error("Offer ID is missing. Cannot delete estimate.");
      return;
    }
    const confirmDelete = window.confirm("Are you sure you want to delete this estimate? This action cannot be undone.");
    if (!confirmDelete) return; 
  
    try {
      const db = firebase.firestore();
      const updatedEstimates = savedEstimates.filter(estimate => estimate.createdAt !== estimateCreatedAt);
  
      await db.collection("offers").doc(offer.id).update({
        net_a_seller_estimates: updatedEstimates
      });
  
      setSavedEstimates(updatedEstimates);
  
      if (selectedEstimate?.createdAt === estimateCreatedAt) {
        setSelectedEstimate(null);
        setFormData({ ...formData, estimateName: "" });
      }
      await fetchUpdatedOfferData();
      toast.success("Estimate deleted successfully!");
    } catch (error) {
      console.error("Error deleting estimate:", error);
      toast.error("Failed to delete estimate. Please try again.");
    }
  };
  
  const fetchUpdatedOfferData = async () => {
    try {
      const db = firebase.firestore();
      const offerDoc = await db.collection("offers").doc(offer.id).get();

      if (offerDoc.exists) {
        const updatedEstimates = offerDoc.data().net_a_seller_estimates || [];
        
        setSavedEstimates(updatedEstimates);
      }

      if (offer?.seller) {
        const sellerDoc = await db.collection("sellers").doc(offer.seller).get();
        if (sellerDoc.exists) {
          const sellerData = sellerDoc.data();
          if (sellerData?.net_a_seller) {
            if (sellerData.net_a_seller.titleFees) {
              setTitleFees(sellerData.net_a_seller.titleFees);
            }
            if (sellerData.net_a_seller.otherFees) {
              setOtherFees(sellerData.net_a_seller.otherFees);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error fetching updated estimates:", error);
    }
  };

  const data = {
    datasets: [
      {
        data: [
          calculatedData?.netAtClose || 0,
          calculatedData?.closingCosts || 0,
          calculatedData?.propertyTaxes || 0
        ],
        backgroundColor: ["#3366FF", "#FFA500", "#6D4C41"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    legend: {
      display: false
    },
    cutoutPercentage: 85,
    animation: {
      animationRotate: true,
      duration: 2000,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
    },
  };

  const switchTab = (tab) => {
    setActiveTab(tab);
  };
  
  const handleEditClick = () => {
    setCustomNetAtClose(calculatedData?.netAtClose?.toFixed(2) || "0.00");
    setIsEditingNetAtClose(true);
  };

  const handleNetAtCloseChange = (e) => {
    setCustomNetAtClose(e.target.value);
  };

  const handleNetAtCloseKeyPress = (e) => {
    if (e.key === "Enter") {
      setIsEditingNetAtClose(false);
  
      let desiredNetAtClose = parseFloat(e.target.value) || 0;
  
      if (desiredNetAtClose <= 0) {
        alert("Net at Close must be a positive value.");
        return;
      }
  
      // Extract necessary values from formData
      const sellerBF = parseFloat(formData.sellerBF_value) / 100;
      const buyerBF = parseFloat(formData.buyerBF_value) / 100;
      const sellerConcession = parseFloat(formData.SellerConcession_seller) || 0;
      const loanBalance = parseFloat(formData.loanBalance) || 0;
      const serviceContract = parseFloat(formData.serviceContract) || 0;
      const miscellaneous = parseFloat(formData.miscellaneous) || 0;
      const taxRate = parseFloat(formData.taxPercent);
      const specialLoanFee = formData.calcType === "VA" ? 65 : formData.calcType === "Cash" ? 55 : 0;
  
      // Initial estimated Sales Price (start with the desired netAtClose)
      let estimatedSalesPrice = desiredNetAtClose;
      let titleInsurance = 0;
      let closingCosts = 0;
      let proratedTaxes = 0;
  
      // Iteratively adjust sales price to match the desired Net At Close
      for (let i = 0; i < 10; i++) {
        // Calculate title insurance based on the estimated sales price
        titleInsurance = calculateTitleInsurance(estimatedSalesPrice);
        formData.titlePolicyExpense === "Seller" ? titleInsurance = titleInsurance : formData.titlePolicyExpense === "Buyer" ? titleInsurance = 0 : titleInsurance = titleInsurance / 2;
        const totalTitleFees = titleFees.reduce((sum, fee) => sum + parseFloat(fee.value || 0), 0) + titleInsurance;

        // Calculate total other fees dynamically
        const totalOtherFees = otherFees.reduce((sum, fee) => sum + parseFloat(fee.value || 0), 0);
        // Calculate prorated taxes
        console.log(formData.closingDate, "formData.closingDate")
        proratedTaxes = calculateProratedTaxes(estimatedSalesPrice, taxRate, formData.closingDate, formData.taxAmountAnnual);
  
        const otherFee =
          (estimatedSalesPrice * sellerBF) +
          (estimatedSalesPrice * buyerBF) +
          serviceContract +
          miscellaneous +
          specialLoanFee;
    
        // Total closing costs
        let closingCosts = totalTitleFees + totalOtherFees + otherFee;
  
        // Adjust estimated Sales Price to match the entered Net at Close
        estimatedSalesPrice = desiredNetAtClose + closingCosts + sellerConcession + loanBalance + proratedTaxes;
      }

  
      // Update state with new calculated values
      setFormData((prev) => ({
        ...prev,
        price: estimatedSalesPrice.toFixed(2),
      }));
  
      // Trigger calculation with new sales price
      handleCalculateClick(estimatedSalesPrice);
    }
  }; 
  
  const handleCalculateClick = (manualSalesPrice = null) => {
    let salesPrice = 
    typeof manualSalesPrice === "number" && !isNaN(manualSalesPrice)
      ? manualSalesPrice
      : parseFloat(formData.price);
      
    if (isNaN(salesPrice) || salesPrice === 0) {
      alert("Sales Price cannot be zero. Please enter a valid amount.");
      return;
    }
    let specialLoanFee = 0;
    let specialLoanFeeLabel = "";

    if (formData.calcType === "VA") {
        specialLoanFee = 65;
        specialLoanFeeLabel = "Termite Fee (VA Loan)";
    } else if (formData.calcType === "Cash") {
        specialLoanFee = 55;
        specialLoanFeeLabel = "Maintenance Certificate (Cash Loan)";
    }

    let titleInsurance = calculateTitleInsurance(salesPrice);
    formData.titlePolicyExpense === "Seller" ? titleInsurance = titleInsurance : formData.titlePolicyExpense === "Buyer" ? titleInsurance = 0 : titleInsurance = titleInsurance / 2;
    const totalTitleFees = titleFees?.reduce((sum, fee) => sum + parseFloat(fee.value || 0), 0) + titleInsurance;
    const totalOtherFees = otherFees?.reduce((sum, fee) => sum + parseFloat(fee.value || 0), 0);
    // const escrowFee = 525;
    // const attorneyFees = 150 + 100;
    // const taxCertificate = 65;
  
  
    const sellerBF = (salesPrice * parseFloat(formData.sellerBF_value)) / 100;
    const buyerBF = (salesPrice * parseFloat(formData.buyerBF_value)) / 100;
    const taxRate = parseFloat(formData.taxPercent);
    const proratedTaxes = calculateProratedTaxes(salesPrice, taxRate, formData.closingDate, formData.taxAmountAnnual);
    const otherFee =
      sellerBF +
      buyerBF +
      parseFloat(formData.serviceContract) +
      parseFloat(formData.miscellaneous) +
      specialLoanFee;
  
    const closingCosts = totalTitleFees + totalOtherFees + otherFee;

    let netAtClose = salesPrice - (closingCosts + parseFloat(formData.SellerConcession_seller) + parseFloat(formData.loanBalance) + proratedTaxes);

    setCalculatedData({
      netAtClose,
      closingCosts, 
      propertyTaxes: proratedTaxes,
      titleFees: totalTitleFees, 
      otherFee, 
      sellerBF, 
      buyerBF,
      titleInsurance,
      specialLoanFee,
      specialLoanFeeLabel
    });
    setCustomNetAtClose(netAtClose.toFixed());
    setShowResults(true);
    setActiveTab(1);
    setTimeout(() => {
      const modalBody = document.querySelector(".seller-net-modal-body");
      if (modalBody) {
        console.log("✅ Scrolling to top of modal");
        modalBody.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        console.log("⚠️ Modal body not found");
      }
    }, 200);

  }; 

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        const modalBody = document.querySelector(".seller-net-modal-body");
        if (modalBody) {
          console.log("✅ Modal body found:", modalBody);
        } else {
          console.log("⚠️ Modal body not found, retrying...");
        }
      }, 300);
    }
  }, [showModal]);
  
  useEffect(() => {
    if (showEditFieldsModal) {
      handleCalculateClick();
    }
  }, [titleFees, otherFees]);

  // const calculateProratedTaxes = (salesPrice, taxRate, closingDate, taxAmountAnnual) => {
  //   if (!closingDate) return 0;

  //   const closingDateObj = new Date(closingDate);
  //   const yearStart = new Date(closingDateObj.getFullYear(), 0, 1); // January 1st

  //   // ✅ Determine Days Owed (Seller's Responsibility)
  //   const daysOwed = Math.floor((closingDateObj - yearStart) / (1000 * 60 * 60 * 24));

  //   let annualPropertyTax = 0;

  //   // ✅ Use taxAmountAnnual if provided, otherwise calculate using taxRate
  //   if (taxAmountAnnual && parseFloat(taxAmountAnnual) > 0) {
  //       annualPropertyTax = parseFloat(taxAmountAnnual);
  //   } else if (taxRate && parseFloat(taxRate) > 0) {
  //       annualPropertyTax = salesPrice * (parseFloat(taxRate) / 100);
  //   } else {
  //       return 0; // If neither taxAmountAnnual nor taxRate is provided, return 0
  //   }

  //   // ✅ Compute Prorated Taxes (Fixes rounding issues)
  //   const proratedTaxes = ((annualPropertyTax / 365) * daysOwed).toFixed(2);

  //   console.log(`Annual Property Tax: $${annualPropertyTax}`);
  //   console.log(`Days Seller Owes: ${daysOwed}`);
  //   console.log(`Prorated Taxes Owed: $${proratedTaxes}`);

  //   return parseFloat(proratedTaxes); // Return as a number
  // };


  const calculateProratedTaxes = (salesPrice, taxRate, closingDate, taxAmountAnnual) => {
    if (!closingDate) return 0;

    const closingDateObj = new Date(closingDate);
    const yearStart = new Date(closingDateObj.getFullYear(), 0, 1); // January 1st

    // ✅ Determine if it's a leap year (to correctly handle February)
    const isLeapYear = (closingDateObj.getFullYear() % 4 === 0 && closingDateObj.getFullYear() % 100 !== 0) || (closingDateObj.getFullYear() % 400 === 0);
    const febDays = isLeapYear ? 29 : 28; // Set February days accordingly

    // ✅ Manually count the days from January 1st to Closing Date
    let daysOwed = 0;
    for (let month = 0; month < closingDateObj.getMonth(); month++) {
        daysOwed += [31, febDays, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    }
    daysOwed += closingDateObj.getDate();

    // ✅ Use `taxAmountAnnual` first, otherwise calculate using `taxRate`
    let annualPropertyTax = 0;
     if (taxType === "amount") {
        annualPropertyTax = parseFloat(taxAmountAnnual) || 0;
    } else {
        annualPropertyTax = salesPrice * (parseFloat(taxRate) / 100) || 0;
    } 
    if (annualPropertyTax <= 0) return 0; 

    // ✅ Compute Prorated Taxes (Fixes rounding issues)
    const proratedTaxes = ((annualPropertyTax / (isLeapYear ? 366 : 365)) * daysOwed).toFixed(2);

    return parseFloat(proratedTaxes); // Return as a number
  };

  // const calculateTitleInsurance = (salesPrice) => {
  //   if (salesPrice <= 100000) return 832;
  //   let base = 832;
  //   let extra = 0;
  //   if (salesPrice > 100000 && salesPrice <= 1000000) {
  //     extra = (salesPrice - 100000) * 0.00527;
  //   } else if (salesPrice > 1000000) {
  //     extra = (900000 * 0.00527) + ((salesPrice - 1000000) * 0.00432);
  //   }
  //   return Math.round(base + extra);
  // };

  const calculateTitleInsurance = (salesPrice) => {
    if (salesPrice <= 100000) {
        // For policies under or equal to $100,000
        const closestFee = titleInsuranceData.under_100k.reduce((closest, item) => {
            return Math.abs(item.price - salesPrice) < Math.abs(closest.price - salesPrice) ? item : closest;
        });
        return closestFee.fee;
    } else {
        // For policies over $100,000
        const range = titleInsuranceData.over_100k.find(item => 
            salesPrice >= item.range[0] && (item.range[1] === null || salesPrice <= item.range[1])
        );

        if (!range) return 0; // Fallback if range not found

        const result = ((salesPrice - range.subtract) * range.multiply_by) + range.add;
        return Math.round(result);
    }
  };

  const resetForm = () => {
    setFormData({
      calcType: "Conventional",
      price: "",
      sellerBF_value: "3",
      buyerBF_value: "3",
      BF_sellerbuyer_Split: "Seller",
      SellerConcession_seller: "0",
      repairs: "",
      miscCosts: "",
      taxPercent: "2.59",
      taxAmountAnnual: "953.15",
      loanBalance: "0",
      interestRate: "4.25",
      closingDate: formattedDate,
      serviceContract: "0",
      miscellaneous: "0",
    });
  
    setShowResults(false);
    setCalculatedData(null);
    setCustomNetAtClose("");
  };

  const toggleSaveEstimateModal = () => {
    setSaveEstimateModal(!saveEstimateModal);
  };

  const handleEstimateSelection = (estimate) => {
    setSelectedEstimate(estimate);
    
    setFormData(estimate);
    setCustomNetAtClose(estimate.customNetAtClose)
    setEstimateName(estimate.estimateName)
    if(!saveClosingCostsForAll) {
      if (estimate.titleFees) {
        setTitleFees(estimate.titleFees);
      }
      if (estimate.otherFees) {
        setOtherFees(estimate.otherFees);
      }
    }
    handleCalculateClick();
    setShowResults(true);
    setActiveTab(1);
  };

  const handleOfferSelection = (estimate) => {
    setSelectedEstimate(estimate);
  
    const updatedData = {
      ...formData,
      calcType: estimate.financingType || formData.calcType,
      price: estimate.purchasePrice || formData.price,
      titlePolicyExpense: estimate.titlePolicyExpense || formData.titlePolicyExpense,
      BF_sellerbuyer_Split: estimate.titlePolicyExpense || formData.BF_sellerbuyer_Split,
      closingDate: estimate.closingDate || formData.closingDate,
      buyerBF_value: estimate.titlePolicyExpense === "Buyer" ? "0" : estimate.titlePolicyExpense === "Split" ? "1.5" : "3",
      sellerBF_value: "3",
    };
  
    setFormData(updatedData);
  
    let salesPrice = parseFloat(updatedData.price);
    if (isNaN(salesPrice) || salesPrice === 0) {
      alert("Sales Price cannot be zero. Please enter a valid amount.");
      return;
    }
  
    let specialLoanFee = 0;
    let specialLoanFeeLabel = "";
    if (updatedData.calcType === "VA") {
      specialLoanFee = 65;
      specialLoanFeeLabel = "Termite Fee (VA Loan)";
    } else if (updatedData.calcType === "Cash") {
      specialLoanFee = 55;
      specialLoanFeeLabel = "Maintenance Certificate (Cash Loan)";
    }
  
    const titleInsurance = calculateTitleInsurance(salesPrice);
    updatedData.titlePolicyExpense === "Seller" ? titleInsurance = titleInsurance : updatedData.titlePolicyExpense === "Buyer" ? titleInsurance = 0 : titleInsurance = titleInsurance / 2;

    const totalTitleFees = titleFees.reduce((sum, fee) => sum + parseFloat(fee.value || 0), 0) + titleInsurance;
    const totalOtherFees = otherFees.reduce((sum, fee) => sum + parseFloat(fee.value || 0), 0);
  
    const sellerBF = (salesPrice * parseFloat(updatedData.sellerBF_value)) / 100;
    const buyerBF = (salesPrice * parseFloat(updatedData.buyerBF_value)) / 100;
    const taxRate = parseFloat(updatedData.taxPercent);
    const proratedTaxes = calculateProratedTaxes(salesPrice, taxRate, updatedData.closingDate, updatedData.taxAmountAnnual);
  
    const otherFee = sellerBF + buyerBF + parseFloat(updatedData.serviceContract) + parseFloat(updatedData.miscellaneous) + specialLoanFee;
    const closingCosts = totalTitleFees + totalOtherFees + otherFee;
  
    let netAtClose = salesPrice - (closingCosts + parseFloat(updatedData.SellerConcession_seller) + parseFloat(updatedData.loanBalance) + proratedTaxes);
  
    setCalculatedData({
      netAtClose,
      closingCosts,
      propertyTaxes: proratedTaxes,
      titleFees: totalTitleFees,
      otherFee,
      sellerBF,
      buyerBF,
      titleInsurance,
      specialLoanFee,
      specialLoanFeeLabel,
    });
  
    setCustomNetAtClose(netAtClose.toFixed(2));
    setShowResults(true);
    setActiveTab(1);
  };
  

  const addOtherFee = () => {
    setOtherFees([...otherFees, { id: Date.now(), name: "", value: 0 }]);
  };
  
  // Remove a Fee
  const removeOtherFee = (id) => {
    setOtherFees(otherFees.filter(fee => fee.id !== id));
  };

  const handleCaptureChart = () => {
    if (doughnutRef.current && doughnutRef.current.chartInstance) {
      const chartCanvas = doughnutRef.current.chartInstance.canvas;
      return chartCanvas.toDataURL("image/png");
    }
    return null;
  };
    
  const generateSnapshotText = () => {
    return `
      Sales Price - $${Number(formData?.price || 0).toLocaleString()}
      Closing Costs - $${Number(calculatedData?.closingCosts || 0).toLocaleString()}
      Loan Balance - $${Number(formData?.loanBalance || 0).toLocaleString()}
      Prorated Tax Owed - $${Number(calculatedData?.propertyTaxes || 0).toLocaleString()}
      Seller Concessions - $${Number(formData?.SellerConcession_seller).toLocaleString()}
      Service Contract - $${Number(formData?.serviceContract).toLocaleString()}
      Net At Close - $${Number(calculatedData?.netAtClose || 0).toLocaleString()}
      
      This estimate, based upon the above sale price, type of financing and projected closing date, has been prepared to assist Seller in estimating costs and proceeds. Amounts will vary depending upon differences between actual and estimated repairs that may occur in the transaction, unpaid loan balances, assessments, liens, impound accounts, charges by lenders, escrow companies, title insurers and other service providers and other items. Not all liens may yet have been identified. Neither Broker nor Agent guarantee these figures represent the actual, or only, amounts and charges.
    `
      .split("\n")
      .map(line => line.trimStart())
      .join("\n");
  };

  return (
    <>
      <Modal className="seller-net-modal-body" scrollable={true} isOpen={showModal} toggle={hideModal} size="lg">
        <ModalHeader toggle={() => {
          hideModal();
           if(offer) {
            setFormData((prevData) => {
              const splitType = offer.titlePolicyExpense || "Seller";
              return {
                ...prevData,
                calcType: offer.financingType || "Conventional",
                price: offer.purchasePrice || "",
                BF_sellerbuyer_Split: splitType,
                closingDate: offer.closingDate || formattedDate,
                buyerBF_value: splitType === "Buyer" ? "0" : splitType === "Split" ? "1.5" : "3",
                sellerBF_value: "3",
              };
            });
            handleOfferSelection(offer);
          }
        }}>Seller Net Sheet</ModalHeader>
        <ModalBody >
          <Row>
            <p className="seller-net-sheet-description">See how much cash you could be walking with after selling your home!</p>
            <Col 
              className="seller-net-mob-form-container" 
              md={{ order: 1, size: showResults ? 6 : 8, offset: showResults ? 0 : 2 }} 
              xs={{ order: 2, size: 12 }}>
              <div className="seller-net-sheet-form-container">
                <form onSubmit={(e) => e.preventDefault()}>
                  <FormGroup>
                    <Label>Loan Type</Label>
                    <Input type="select" name="calcType" value={formData.calcType} onChange={handleChange}>
                      <option value="Cash">Cash</option>
                      <option value="USDA">USDA</option>
                      <option value="Conventional">Conventional</option>
                      <option value="FHA">FHA</option>
                      <option value="VA">VA</option>
                      <option value="Seller Finance">Seller Finance</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label>Sales Price</Label>
                    <Input 
                      type="text" 
                      name="price" 
                      value={formData.price ? Number(formData.price).toLocaleString("en-US") : ""} 
                      onChange={handleChange}
                      placeholder="Required"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Seller Broker Fee (%)</Label>
                    <Input type="text" name="sellerBF_value" value={formData.sellerBF_value} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Buyer Broker Fee (%)</Label>
                    <Input type="text" name="buyerBF_value" value={formData.buyerBF_value} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Broker Fee Split</Label>
                    <Input type="select" name="BF_sellerbuyer_Split" value={formData.BF_sellerbuyer_Split} onChange={handleChange}>
                      <option value="Buyer">Buyer</option>
                      <option value="Seller">Seller</option>
                      <option value="Split">Split</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label>Title Policy Expense</Label>
                    <Input type="select" name="titlePolicyExpense" value={formData.titlePolicyExpense} onChange={handleChange}>
                      <option value="Buyer">Buyer</option>
                      <option value="Seller">Seller</option>
                      <option value="Split">Split</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label>Seller Concession</Label>
                    <Input type="text" name="SellerConcession_seller" value={formData.SellerConcession_seller} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Service Contract</Label>
                    <Input type="text" name="serviceContract" value={formData.serviceContract} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Repairs</Label>
                    <Input type="text" name="repairs" value={formData.repairs} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Misc. Costs</Label>
                    <Input type="text" name="miscCosts" value={formData.miscCosts} onChange={handleChange} />
                  </FormGroup>

                  <div style={{marginBottom: 16}} className="tax-type-radio">
                    <FormGroup className="mb-0">
                      <Label className="label-font">Tax Type:</Label>
                    </FormGroup>
                    <FormGroup check className="radio-btn">
                      <Label>
                        <Input
                          type="radio"
                          name="taxType"
                          value="percent"
                          checked={taxType === "percent"}
                          onChange={() => {
                            setTaxType("percent");
                            setFormData({ ...formData, taxAmountAnnual: "" });
                          }}
                        />{" "}
                        <span className={isIOS ? "ios-radio-label" : ""}>Percentage (%)</span>
                      </Label>
                    </FormGroup>
                    <FormGroup check className="radio-btn">
                      <Label>
                        <Input
                          type="radio"
                          name="taxType"
                          value="amount"
                          checked={taxType === "amount"}
                          onChange={() => {
                            setTaxType("amount");
                            setFormData({ ...formData, taxPercent: "" });
                          }}
                        />{" "}
                        <span className={isIOS ? "ios-radio-label" : ""}>Annual ($)</span>
                      </Label>
                    </FormGroup>
                  </div>

                  <FormGroup>
                    <Label>Property Taxes (%)</Label>
                    <Input type="text" name="taxPercent" value={formData.taxPercent} disabled={taxType !== "percent"} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Annual Tax Amount</Label>
                      <Input type="text" name="taxAmountAnnual" value={formData.taxAmountAnnual} disabled={taxType !== "amount"} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Seller Loan Balance</Label>
                    <Input type="text" name="loanBalance" value={formData.loanBalance} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Seller Interest Rate</Label>
                    <Input type="text" name="interestRate" value={formData.interestRate} onChange={handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Closing Date</Label>
                    <Input type="date" name="closingDate" value={formData.closingDate} onChange={handleChange} />
                  </FormGroup>

                  <Button type="submit" onClick={handleCalculateClick}>Calculate</Button>

                  {showResults && <button className="save-estimate-btn" onClick={toggleSaveEstimateModal}>
                      {selectedEstimate ? "Update Estimate" : "Save Estimate"}
                    </button>}
                </form>
              </div>
            </Col>
            {showResults && <Col md={{ order: 2, size: 6 }} xs={{ order: 1, size: 12 }} className="pr-0">
              <div className="tab-button-group">
                <button 
                  className={`tab-button ${activeTab === 0 ? "active" : ""}`} 
                  onClick={() => switchTab(0)}
                >
                  Multiple Offers
                </button>
                <button 
                  className={`tab-button ${activeTab === 1 ? "active" : ""}`} 
                  onClick={() => {
                    handleCalculateClick()
                    switchTab(1)}
                  }
                >
                  Net Sheet
                </button>
                <button 
                  className={`tab-button ${activeTab === 2 ? "active" : ""}`} 
                  onClick={() => switchTab(2)}
                >
                  Saved Estimates
                </button>
              </div>
              <div className="net-sheet-result-container">
                {activeTab === 0 && (
                <div>
                  {!!multipleOffers?.length ? multipleOffers.map((offer, index) => (
                    <div 
                      key={index} 
                      className="estimate-item"
                      onClick={() => handleOfferSelection(offer)}
                    >
                      <div className="multiple-offer-row-space-btwn">
                        <p className="estimate-title">${Number(offer.purchasePrice || 0).toLocaleString()}</p>
                        <button 
                          className="go-to-offer"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.location.href = `/offers/${offer.id}`;
                          }}
                        >Go To Offer</button>
                      </div>
                      <div className="multiple-offer-row-space-btwn">
                        <p>Offer Price</p>
                        <p>${Number(offer.purchasePrice || 0).toLocaleString()}</p> 
                      </div>
                      <div className="multiple-offer-row-space-btwn">
                        <p>Net at Close</p>
                        <p>${Number(offer.estimated_net_at_close || 0).toLocaleString()}</p>
                      </div>
                      <p className="estimate-date-item">
                        {offer.createdAt ? moment.unix(offer.createdAt).format("MM/DD/YYYY") : "No Date"}
                      </p>
                    </div>
                  )) : <p>No offers found</p>}
                </div>
                )}

                {activeTab === 1 && <div className="seller-net-chart-container text-center">
                  <h4 className="mb-4">Estimated Proceeds</h4>
                  <img className="refresh-icon" onClick={resetForm} src={require("../../src/assets/icons/refresh.png")}/>
                  <div className="seller-net-doughnut-container">
                    <Doughnut ref={doughnutRef} data={data} options={options}  width={160} height={160} />
                    <div style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                    }}>
                      {isEditingNetAtClose ? (
                        <input 
                          type="text"
                          className="net-sheet-edit-input"
                          value={customNetAtClose}
                          onChange={handleNetAtCloseChange}
                          onKeyDown={handleNetAtCloseKeyPress}
                          onBlur={() => {
                            handleNetAtCloseKeyPress({ key: "Enter" });
                          }}
                          autoFocus
                        />
                      ) : (
                        <strong className="net-sheet-result">
                          ${Number(customNetAtClose).toLocaleString()}
                        </strong>
                      )}
                      {!isEditingNetAtClose && (
                        <p 
                          className="net-sheet-edit-btn"
                          onClick={handleEditClick}
                        >Edit</p>
                      )}
                    </div>
                  </div>
                  <div className="net-sheet-summary mt-4">
                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#D3D3D3" }}></span>
                        Sales Price
                      </div>
                      <div className="net-sheet-value">${Number(formData?.price).toLocaleString()}</div>
                    </div>

                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#32CD32" }}></span>
                        Loan Balance
                      </div>
                      <div className="net-sheet-value">${Number(formData?.loanBalance).toLocaleString()}</div>
                    </div>

                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#6D4C41" }}></span>
                        Prorated Tax Owed
                      </div>
                      <div className="net-sheet-value">${Number(calculatedData?.propertyTaxes).toLocaleString(undefined, { minimumFractionDigits: 1 })}</div>
                    </div>

                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#FFA500" }}></span>
                        Closing Costs
                      </div>
                      <div className="net-sheet-value">${Number(calculatedData?.closingCosts).toLocaleString(undefined, { minimumFractionDigits: 1 })}</div>
                    </div>

                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#FF4500" }}></span>
                        Seller Concessions
                      </div>
                      <div className="net-sheet-value">
                        ${Number(formData?.SellerConcession_seller).toLocaleString()}
                      </div>
                    </div>

                    <div className="net-sheet-row">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#00BFFF" }}></span>
                        Service Contract
                      </div>
                      <div className="net-sheet-value">
                      ${Number(formData?.serviceContract).toLocaleString()}
                      </div>
                    </div>

                    <div className="net-sheet-row net-sheet-total">
                      <div className="net-sheet-label">
                        <span className="dot" style={{ background: "#3366FF" }}></span>
                        Net at Close
                      </div>
                      <div className="net-sheet-value">${Number(calculatedData?.netAtClose).toLocaleString()}</div>
                    </div>
                    <Button
                      className="share-pdf-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowShareModal(true)
                      }}
                    >
                      Share or download PDF
                      <img
                        className={"arrow-down-unflipped"}
                        src={require("../assets/icons/share.png")}
                      />
          
                    </Button>
                    <Button
                      className="view-scoring-details-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleClosingCosts();
                      }}
                    >
                      {showClosingCosts ? "Hide Detailed Closing Costs" : "View Detailed Closing Costs"}
                      <img
                        className={showClosingCosts ? "arrow-down-flipped" : "arrow-down-unflipped"}
                        src={require("../assets/icons/list-arrow-down-green.png")}
                      />
          
                    </Button>
                    {showClosingCosts && (
                      <div className="detailed-closing-costs">
                        <h5 className="mt-3">Detailed Closing Costs</h5>
                        <img 
                          className="edit-pencil" 
                          src={require("../assets/icons/edit.png")}
                          onClick={() => setShowEsitFieldsModal(true)}  
                        />
                        <div className="cost-item" style={{borderBottomWidth: 0}}><span>Total Closing Costs:</span> <span>${parseFloat(calculatedData?.closingCosts).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</span></div>
        
                        <div className="closing-cost-section">
                          <h6>Title Fees</h6>
                          <div className="cost-item"><span>Owner's Title Policy:</span> <span>${parseFloat(calculatedData?.titleInsurance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>

                          {titleFees.map((fee, key) => (
                            <div key={key} className="cost-item"><span>{fee.name}</span> <span>${fee.value}</span></div>
                          ))}
                        </div>

                        <div className="closing-cost-section mt-3">
                          <h6>Other Fees</h6>
                          <div className="cost-item"><span>Buyer Broker Fee:</span> <span>${parseFloat(calculatedData?.buyerBF).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>
                          <div className="cost-item"><span>Seller Broker Fee:</span> <span>${parseFloat(calculatedData?.sellerBF).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>
                          <div className="cost-item"><span>Service Contract:</span> <span>${parseFloat(formData.serviceContract).toLocaleString()}</span></div>
                          <div className="cost-item"><span>Miscellaneous:</span> <span>${parseFloat(formData.miscellaneous).toLocaleString()}</span></div>
                          {calculatedData?.specialLoanFee > 0 && (
                            <div className="cost-item">
                              <span>{calculatedData?.specialLoanFeeLabel}:</span>
                              <span>${parseFloat(calculatedData?.specialLoanFee).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </div>
                          )}
                          {!!otherFees?.length && otherFees.map((fee, key) => (<div key={key} className="cost-item"><span>{fee.name}</span> <span>${parseFloat(fee.value).toLocaleString()}</span></div>))}
                        </div>
                      </div>
                    )}
                    
                  </div>
                </div>}
                {activeTab === 2 && (
                  <div>
                    {!!savedEstimates?.length ? savedEstimates?.map((estimate, index) => (
                      <div 
                        key={index} 
                        className={`estimate-item ${selectedEstimate?.createdAt === estimate.createdAt ? "selected-estimate" : ""}`}
                        onClick={() => handleEstimateSelection(estimate)}
                      >
                        <p className="estimate-title">{estimate.estimateName}</p>
                        <p className="estimate-item-line">Offer Price: ${Number(estimate.price).toLocaleString()}</p>
                        <p className="estimate-item-line">Net at Close: ${Number(estimate.customNetAtClose || 0).toLocaleString()}</p>
                        <p className="estimate-date-item">{moment(estimate.createdAt * 1000).format("MM/DD/YYYY")}</p>
                        <img 
                          className="delete-estimate-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteEstimate(estimate.createdAt);
                          }}
                          src={require("../assets/icons/bin.png")}
                          alt="Delete"
                        />
                      </div>
                    )) : <p>No estimates found</p>}
                  </div>
                )}
              </div>
            </Col>}
          </Row>
        </ModalBody>
        <ModalFooter className="seller-net-footer">
          <Button color="secondary" onClick={() => {
            hideModal()
            if(offer) {
              setFormData((prevData) => {
                const splitType = offer.titlePolicyExpense || "Seller";
                return {
                  ...prevData,
                  calcType: offer.financingType || "Conventional",
                  price: offer.purchasePrice || "",
                  BF_sellerbuyer_Split: splitType,
                  closingDate: offer.closingDate || formattedDate,
                  buyerBF_value: splitType === "Buyer" ? "0" : splitType === "Split" ? "1.5" : "3",
                  sellerBF_value: "3",
                };
              });
              handleOfferSelection(offer);
            }
          }}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Save Estimate Modal */}
      <Modal isOpen={saveEstimateModal} toggle={toggleSaveEstimateModal}>
        <ModalHeader toggle={toggleSaveEstimateModal}>
          {selectedEstimate ? "Update Estimate" : "Save Estimate"}
        </ModalHeader>
        <ModalBody>
          <p>
            {selectedEstimate
              ? "Update this estimate with the new values and keep track of changes."
              : "Save this estimate for future reference and easy comparison. This allows you to revisit your projected net proceeds, closing costs, and other key financial details at any time."}
          </p>
          <FormGroup>
            <Label>Estimate Name</Label>
            <Input
              type="text"
              placeholder="Enter estimate name"
              value={estimateName}
              onChange={(e) => setEstimateName(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleSaveEstimateModal}>Cancel</Button>
          <Button color="primary" onClick={handleSaveEstimate} disabled={savingEstimate}>
            {savingEstimate ? "Saving..." : selectedEstimate ? "Update Estimate" : "Save Estimate"}
          </Button>
        </ModalFooter>
      </Modal>


      {/* edit closing costs modal */}
      <Modal isOpen={showEditFieldsModal} toggle={() => setShowEsitFieldsModal(false)}>
        <ModalHeader toggle={() => setShowEsitFieldsModal(false)}>Seller Closing Costs</ModalHeader>
        <ModalBody>

          <h6 className="modal-section-title">Title Fees</h6>
          <img 
            className="fee-add-icon" 
            src={require("../assets/icons/add.png")} 
            alt="Add Fee" 
            onClick={() => setTitleFees([...titleFees, { name: "", value: 0 }])} 
          />
          {titleFees.map((fee, index) => (
            <div key={index} className="editable-fee-row">
              <div className="label-div">
                <Input
                  type="text"
                  className="fee-name-input"
                  value={fee.name}
                  placeholder="Fee Name"
                  onChange={(e) => {
                    const updatedFees = [...titleFees];
                    updatedFees[index].name = e.target.value;
                    setTitleFees(updatedFees);
                  }}
                />
              </div>
              <div>
                <div className="editable-input-value-container">
                  <Input
                    type="number"
                    className="fee-amount-input"
                    value={fee.value}
                    placeholder="Amount"
                    onChange={(e) => {
                      const updatedFees = [...titleFees];
                      updatedFees[index].value = e.target.value;
                      setTitleFees(updatedFees);
                    }}
                  />
                  <img 
                    onClick={() => {
                    const updatedFees = titleFees.filter((_, i) => i !== index);
                    setTitleFees(updatedFees);
                  }}
                  src={require("../assets/icons/bin.png")}
                  />
                </div>
              </div>
            </div>
          ))}

          <h6 className="modal-section-title">Other Fees</h6>
          <img 
            className="fee-add-icon" 
            src={require("../assets/icons/add.png")} 
            alt="Add Fee" 
            onClick={addOtherFee} 
          />
          {otherFees.map((fee, index) => (
            <div key={index} className="editable-fee-row">
              <div className="label-div">
                <Input
                  type="text"
                  className="fee-name-input"
                  value={fee.name}
                  placeholder="Enter label"
                  onChange={(e) => {
                    const updatedFees = [...otherFees];
                    updatedFees[index].name = e.target.value;
                    setOtherFees(updatedFees);
                  }}
                />
              </div>
              <div>
                <div className="editable-input-value-container">
                  <Input
                    type="number"
                    className="fee-amount-input"
                    value={fee.value}
                    placeholder="Amount"
                    onChange={(e) => {
                      const updatedFees = [...otherFees];
                      updatedFees[index].value = e.target.value;
                      setOtherFees(updatedFees);
                    }}
                  />
                  <img
                    onClick={() => {
                      const updatedFees = otherFees.filter((_, i) => i !== index);
                      setOtherFees(updatedFees);
                    }}
                    src={require("../assets/icons/bin.png")}
                  />
                </div>
              </div>
            </div>
          ))}

        </ModalBody>

        <ModalFooter className="edit-fee-modal-footer">
          <Button 
            className="modal-cancel-btn" 
            color="secondary" 
            // onClick={() => setShowEsitFieldsModal(false)}
            onClick={() => {
              const hasTitleFeesChanged = JSON.stringify(titleFees) !== JSON.stringify([
                { name: "Escrow Fee", value: 525 },
                { name: "Attorney Fee (Deed)", value: 150 },
                { name: "Attorney Fee (Release of Lien)", value: 100 },
                { name: "Tax Certificate", value: 65 }
              ]);
              
              const hasOtherFeesChanged = otherFees.length > 0;
          
              if (hasTitleFeesChanged || hasOtherFeesChanged) {
                setShowEsitFieldsModal(false);
                setClosingCostsEditConfirmation(true);
              } else {
                setShowEsitFieldsModal(false);
              }
            }}
          >
            Done
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={closingCostsEditConfirmation} toggle={() => setClosingCostsEditConfirmation(false)}>
        <ModalHeader toggle={() => setClosingCostsEditConfirmation(false)}>Edit Closing Costs</ModalHeader>
        <ModalBody>
          <div className="EstimateContainer">
            <p className="edit-closing-costs-description">
              You have updated the closing costs. Would you like to apply these changes only for this estimate or 
              for all future estimates to keep them consistent?
            </p>
            <div className="edit-closing-costs-confirmation-btns">
              <div>
                <Button className="currentestimatebtn" onClick={() => {
                  setSaveClosingCostsForAll(false);
                  setClosingCostsEditConfirmation(false);
                }}>
                  For this estimate only
                </Button>
              </div>
              <div>
                <Button className="allestimatebtn" onClick={() => {
                  setSaveClosingCostsForAll(true);
                  setClosingCostsEditConfirmation(false);
                }}>
                  For all future estimates
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={showShareModal} toggle={() => { 
          setShowShareModal(false);
          setShowSnapshot(false);
      }}>
        <ModalHeader toggle={() => { 
          setShowShareModal(false);
          setShowSnapshot(false);
        }}>
          {showSnapshot ? "Estimated Seller Proceeds" : "Share"}
        </ModalHeader>
        
        <ModalBody>
          {showSnapshot ? (
            <>
              <div className="snapshot-container">
                {generateSnapshotText()?.trim()}
              </div>
              <div className="snapshot-footer">
                <Button color="secondary" style={{borderRadius: 4}} onClick={() => setShowSnapshot(false)}>Back</Button>
                <Button
                  className="copy-to-clipboard-btn"
                  onClick={() => {
                    const snapshotText = generateSnapshotText()?.trim();
                    if (snapshotText) {
                      navigator.clipboard
                        .writeText(snapshotText)
                        .then(() => {
                          setIsCopied(true);
                          setTimeout(() => {
                            setIsCopied(false);
                          }, 5000);
                        })
                        .catch((err) => console.error("Failed to copy: ", err));
                    }
                  }}
                >
                  {isCopied ? "Copied!" : "Copy to Clipboard"}
                </Button>
              </div>
            </>
          ) : (
            <div className="d-flex flex-column gap-2 full-report-button-container">
              <Button className="full-report-button" id="emailbtn" onClick={() => setShowSnapshot(true)}>
                Text snapshot
              </Button>
              <PDFDownloadLink
                document={
                  <FullReportPDF
                    offer={offer}
                    formData={formData}
                    calculatedData={calculatedData}
                    titleFees={titleFees}
                    otherFees={otherFees}
                    chartImage={handleCaptureChart()}
                    currentSeller={currentSeller}
                  />
                }
                fileName="Net_Sheet_Report.pdf"
              >
                {({ loading }) => (
                  <Button className="full-report-button" id="printoutbtn">
                    <img
                      src="https://d2w998roo7cij6.cloudfront.net/cf-cdn/assets/images/app_images/FR_Print.png"
                      alt="Print"
                      className="Temp_icon"
                    />
                    {loading ? "Loading Document..." : "Download Full Report"}
                  </Button>
                )}
              </PDFDownloadLink>
            </div>
          )}
        </ModalBody>
      </Modal>

    </>
  );
};

export default SellerNetSheetModal;